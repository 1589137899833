import styled from 'styled-components'
import Box from '@local/shared/components/System/Box'
import React from 'react'

const BlogBoxStyled = styled(Box)`
  & + & {
    margin-top: 0;
    padding-top: 0;

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-top: 2em;
      padding-top: 2em;
    }
  }
  .gatsby-resp-image-background-image,
  .gatsby-resp-image-image {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }

  blockquote {
    border-left: solid 0.175em ${({ theme }) => theme.palette.text.blockquote};
    padding-left: 1em;
    margin: 1em 0;
    background-color: ${({ theme }) => theme.palette.yellow.light1};
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  strong {
    font-weight: 700;
  }
  p {
    margin-bottom: 0.75em;
  }

  p:last-child {
    margin-bottom: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
  }

  p + ul,
  p + ol {
    margin-top: -0.5em;
  }

  ol,
  ul {
    margin-top: 0.5em;
  }

  & > p:last-of-type,
  blockquote > p:last-of-type {
    margin-bottom: 0;
  }
`

const BlogBox = ({ children, ...props }) => (
  <BlogBoxStyled
    py={'2em'}
    pl={{ xs: '2em', md: '2.5em' }}
    pr={{ xs: '1em', md: '2.5em' }}
    maxWidth={'800px'}
    margin={'auto'}
    bgcolor={'white'}
    borderRadius={'10px'}
    md={{ border: 'solid 5px', borderColor: 'blue.light0' }}
    {...props}
  >
    {children}
  </BlogBoxStyled>
)

export default BlogBox
