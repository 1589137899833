import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'
import BlogBox from 'src/components/BlogBox'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Button from 'src/components/Button'
import NetlifyForm from 'src/components/NetlifyForm'
import InputGroup from 'src/components/InputGroup'

const ImgFrame = styled(Box)`
  border-radius: 50%;
  overflow: hidden;
  border: solid 5px ${({ theme }) => theme.palette.blue.light1};
`

const BlogContact = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "savvas-profile.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log('data', data)
  const imgSize = '10em'
  return (
    <BlogBox>
      <Box display={'flex'} flexDirection={{ xs: 'column', sm: 'row' }}>
        <Box flex={'1'} mx={{ xs: 'auto', sm: 0 }} mb={{ xs: '1em', sm: 0 }}>
          <ImgFrame height={imgSize} width={imgSize} mr={'2em'}>
            <Box height={1} as={Img} fluid={data.file.childImageSharp.fluid} />
          </ImgFrame>
        </Box>
        <Box>
          <Typography variant={'h1'} mb={'0.25em'}>
            Blog by Savvas Nicholas
          </Typography>
          <Typography variant={'body'} mb={'1em'} as={'div'}>
            Join me on my journey as I explore how to become a wild Homo sapiens
            in a world of farm animals.
          </Typography>
          <Box my={'1em'}>
            <NetlifyForm
              name={'blog-subscribe'}
              action={'/thank-you/subscribe'}
            >
              <InputGroup
                id={'blog-email'}
                name={'email'}
                type={'email'}
                placeholder={'Email'}
              />
              <Button mt={'0.5em'} type={'submit'}>
                Subscribe
              </Button>
            </NetlifyForm>
          </Box>
        </Box>
      </Box>
    </BlogBox>
  )
}

export default BlogContact
