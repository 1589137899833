import React from 'react'
import BlogBox from 'src/components/BlogBox'
import Typography from '@local/shared/components/System/Typography'
import { Link } from 'gatsby'
import styled from 'styled-components'

const TitleStyled = styled(Typography)`
  margin-bottom: 0.5em !important;
`

const BlogPostBox = ({ date, slug, title, children, ...props }) => {
  return (
    <BlogBox {...props}>
      <Typography variant={'note'} mb={'0.25em'}>
        {date}
      </Typography>
      <Link to={`/blog/${slug}`}>
        <TitleStyled variant={'h1'} mb={'0.5em'}>
          {title}
        </TitleStyled>
      </Link>
      {children}
    </BlogBox>
  )
}

export default BlogPostBox
