import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import Box from '@local/shared/components/System/Box'
import Typography from '@local/shared/components/System/Typography'
import BlogContact from 'src/components/BlogContact'
import BlogPostBox from 'src/components/BlogPostBox'
import styled from 'styled-components'

const StyledTeaser = styled(Box)`
  max-height: 10em;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    color: white;
  }
`

const BlogIndex = ({ data, location }) => {
  const blogs = data.allMdx.edges.map(e => e.node)
  console.log('data', data, blogs)
  return (
    <PageLayout>
      <Seo
        title="WildLife Blog"
        description={`Join me on my journey as I explore how to become a wild Homo sapiens in a world of farm animals.`}
        shortTeaser={`Join me on my journey as I explore how to become a wild Homo sapiens in a world of farm animals.`}
        locationPathname={location.pathname}
      />
      <Box
        width={1}
        py={'1em'}
        md={{ py: '4em', bgcolor: 'blue.0' }}
        borderBottom={'solid 0.5em'}
        borderColor={'blue.light0'}
      >
        <BlogContact />
        {blogs.map(b => (
          <BlogPostBox
            key={b.fields.slug}
            slug={b.fields.slug}
            date={b.frontmatter.created}
            title={b.frontmatter.title}
          >
            <StyledTeaser>
              <Typography variant={'body'}>
                {b.frontmatter.shortTeaser}
              </Typography>
            </StyledTeaser>
          </BlogPostBox>
        ))}
      </Box>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fields: { sourceName: { eq: "blog" } } }
      sort: { fields: [frontmatter___created], order: DESC }
    ) {
      edges {
        node {
          teaser: excerpt(pruneLength: 1000)
          fields {
            slug
          }
          frontmatter {
            created(formatString: "MMMM DD, YYYY")
            title
            shortTeaser
          }
        }
      }
    }
  }
`

export default BlogIndex
